import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/login.vue'],resolve),
  },
  {
    path: '/about',
    name: 'about',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/about.vue'],resolve),
  },
  {
    path: '/companyReport',
    name: 'companyReport',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/companyReport.vue'],resolve),
  },
  {
    path: '/quartercompanyReport',
    name: 'quartercompanyReport',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/quartercompanyReport.vue'],resolve),
  },
  {
    path: '/quarterthreeTable',
    name: 'quarterthreeTable',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/quarterthreeTable.vue'],resolve),
  },
  {
    path: '/threeTable',
    name: 'threeTable',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/threeTable.vue'],resolve),
  },
  {
    path: '/myCompany',
    name: 'myCompany',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/myCompany.vue'],resolve),
  },
  {
    path: '/companyInfo',
    name: 'companyInfo',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/compayInfo.vue'],resolve),
  },
  {
    path: '/setcompanyInfo',
    name: 'setcompanyInfo',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/setcompanyInfo.vue'],resolve),
  },

  {
    path: '/index',
    name: 'index',
    meta:{
      tab: true,
    },
    component:resolve => require(['@/views/index.vue'],resolve),
  },
  {
    path: '/my',
    name: 'my',
    meta:{
      tab: true,
    },
    component:resolve => require(['@/views/my.vue'],resolve),
  },
  {
    path: '/reportDetail',
    name: 'reportDetail',
    meta:{
      tab: false,
    },
    component:resolve => require(['@/views/reportDetail.vue'],resolve),
  },{
    path: '/creditQua',
    name: 'creditQua',
    meta:{
      tab: false,
    },
    title:"信用质量评分",
    component:resolve => require(['@/views/creditQua.vue'],resolve),
  },{
    path: '/survivalAbility',
    name: 'survivalAbility',
    meta:{
      tab: false,
    },
    title:"企业生存能力评分",
    component:resolve => require(['@/views/survivalAbility.vue'],resolve),
  },{
    path: '/businessCapacity',
    name: 'businessCapacity',
    meta:{
      tab: false,
    },
    title:"企业经营能力指数",
    component:resolve => require(['@/views/businessCapacity.vue'],resolve),
  },{
    path: '/businessOpera',
    name: 'businessOpera',
    title:"企业经营透明指数",
    component:resolve => require(['@/views/businessOpera.vue'],resolve),
  },{
    path: '/health',
    name: 'health',
    meta:{
      tab: false,
    },
    title:"企业经营透明指数",
    component:resolve => require(['@/views/health.vue'],resolve),
  },{
    path: '/compete',
    name: 'compete',
    meta:{
      tab: false,
    },
    title:"同业竞争指数",
    component:resolve => require(['@/views/compete.vue'],resolve),
  },{
    path: '/popularFeeling',
    name: 'popularFeeling',
    meta:{
      tab: false,
    },
    title:"企业舆情",
    component:resolve => require(['@/views/popularFeeling.vue'],resolve),
  },{
    path: '/report',
    name: 'report',
    meta:{
      tab: false,
    },
    title:"报告",
    component:resolve => require(['@/views/report.vue'],resolve),
  },{
    path: '/getLmxDesc',
    name: 'getLmxDesc',
    meta:{
      tab: false,
    },
    title:"报告",
    component:resolve => require(['@/views/getLmxDesc.vue'],resolve),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
