export default {
  SET_USER_INFO (state, data) {
    state.userInfo = data;
  },
  SET_REPORT_ITEM (state, data) {
    state.reportItem = data;
  },
  SET_SCORE_ITEM (state, data) {
    state.scoreItem = data;
  },

  SET_COMPANY_NAME (state, data) {
    state.companyName = data;
  },
  SET_COMPANY_AVATAR (state, data) {
    state.companyAvatar = data;
  },
  SET_COMPANY_MAN (state, data) {
    state.companyMan = data;
  },
}