<template>
  <div id="app">
    <router-view/>
    <div v-if="visible" style="height:5rem"></div>
    <div id="app_nav" v-show="visible">
      <div class="app_link" exact >
        <router-link to="/index">
          <img
            class="img"
            :src="
              '/index' === $route.path
                ? tabBarImgArr[0].selected
                : tabBarImgArr[0].normal
            "
          />
          <p class="fontS_15">首页</p>
        </router-link>
      </div>
      <div class="app_link"  exact>
        <router-link to="/my">
          <img
            class="img"
            :src="
              '/my' === $route.path
                ? tabBarImgArr[1].selected
                : tabBarImgArr[1].normal
            "
          />
          <p class="fontS_15">我的</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      visible:true,
      tabBarImgArr: [
        {
          normal: require("../src/assets/shouye-weidianji-icon@2x.png"),
          selected: require("../src/assets/shouye-dianji-icon@2x.png"),
        },
        {
          normal: require("../src/assets/wode-weidianji-icon@2x.png"),
          selected: require("../src/assets/wode-dianji-icon@2x.png"),
        },
      ],
    }
  },
  mounted(){
    console.log(this.$route.path)
  },
   watch: {
    $route: {
      handler(n) {
        console.log(n)
        this.visible = n.meta.tab;
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
#app{
    background-color: #F7F7F7;  
}
#app_nav {
  width: 100%;
  z-index: 100;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.39rem;
  background-color: #fff;
  box-shadow: 0px -4px 8px 0px #F5F7FA;
}

#app_nav .app_link {
  flex: 1;
  text-align: center;
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151B62;
}
#app_nav .app_link .img {
  width: 1.5rem;
  height: 1.5rem;
}
 
.normal1{
  color: #616B80 !important; 
  font-size:2rem;
}
.active{
  color: #151B62 !important;
  font-size:2rem;
}
 
</style>
