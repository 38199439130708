import axios from 'axios'
import store from '../store';
import router from "../router";
// 创建axios实例
const Http = axios.create({
  //baseURL: ajaxUrl, // api 的 base_url
  timeout: 5000000000000, // 请求超时时间
  headers:{
  	'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'
    // 'Content-Type':"multipart/form-data" 
  }
})
// request拦截器
Http.interceptors.request.use(
  config => {
    if (store.state.userInfo) {
      //config.headers['X-Token'] = store.state.userInfo.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
Http.interceptors.response.use(
  response => {
    const res = response.data;
    if(res.SuccessCode == 200){
      return response.data
    }else{
      if(res.ErrorCode == 2001){
        Message.info("密码错误")
      }
      if(res.ErrorCode == 2004 || res.ErrorCode == 2005){
        router.replace({
          path: '/login',
        })
      }
    }
    return response.data

  },
  error => {
    if(error.code!=12){
    	Message.info({
	      content: error.info
	    })
    }
    return Promise.reject(error)
  }
)

export default Http
