import api from "../utils/reset"
export default {
  // 登录
  async login({ commit },param){
    const res = await api.Apost("/applet/v1/Login/login",param);
    return res
  },

  // 首页
  async bindList({ commit },param){
    const res = await api.Apost("/applet/v1/Login/bindList",param);
    return res
  },

  // 查看公司详情
  async getScore({ commit },param){
    const res = await api.Apost("/applet/v1/Login/getScore",param);
    return res
  },

  // 编辑投后企业信息
  async editManageCompany({ commit },param){
    const res = await api.Apost("/applet/v1/Login/editManageCompany",param);
    return res
  },

  // 查看投后企业信息
  async getAccountInfo({ commit },param){
    const res = await api.Apost("/applet/v1/Login/getAccountInfo",param);
    return res
  },

  // 上传
  async upload1({ commit },param){
    const res = await api.upload("/applet/v1/Freedom/index2",param);
    return res
  },

  
  // 详情
  async getLmxDesc({ commit },param){
    const res = await api.upload("/applet/v1/Login/getLmxDesc",param);
    return res
  },
}