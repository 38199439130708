import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../src/style/common.css"
import "../src/style/common1.scss"
import './config/rem'
import axios from 'axios'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)


Vue.use(axios);
 
import Vant from 'vant';
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
